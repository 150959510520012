import HomeIcon from '@mui/icons-material/Home';
import { SvgIconProps } from '@mui/material';

/**
 * Modelo das definições de um item do menu lateral
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 */
export interface MenuLateralItemModel {
  label: string | JSX.Element;
  Icon?: (props: SvgIconProps) => JSX.Element;
  route?: string;
  childrens?: MenuLateralItemModel[];
  childrensBackgroundColor?: LevelColors;
}

/**
 * Enum de cores de fundo dos itens do menu pra cada nível.
 * O primeiro nível são os filhos do primeiro item.
 */
export enum LevelColors {
  FIRST_LEVEL = '#33384F',
  SECOND_LEVEL = '#1B1D26'
}

/**
 * Lista de definições dos itens do menu lateral
 */
const itensMenuLateral: MenuLateralItemModel[] = [
  {
    label: 'Gantt',
    Icon: props => <HomeIcon {...props} />,
    route: '/app/gantt'
  },
];

export default itensMenuLateral;
