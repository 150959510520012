const enUS = {
  usuario: {
    usuarios: 'Users',
    cadastroDeUsuario: 'User registration',
    edicaoDeUsuario: 'User Edition',
    desejaDeletarEsteUsuario: 'Do you want to delete this user?',
    usuarioDeletadoComSucesso: 'User successfully deleted',
    representante: 'Representative',
    gerente: 'Manager',
    tecnico: 'Technician',
    produtor: 'Producer',
    tipoDeUsuario: 'User type'
  }
};

export default enUS;
