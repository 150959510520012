import React, { useEffect, useState } from 'react';
import viewsRoutes from 'root-views/views-routes';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useStyles } from 'root-views/app.styles';
import MenuLateral from 'root-components/menulateral/menu-lateral';
import MainToolbar from 'root-components/maintoolbar/main-toolbar';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import { Loading } from '@kepha/sumora-react-components/lib';
import { useComponentDidMount } from '@kepha/sumora-react-components/lib/utils/hooks';
import Authentication from 'root-resources/oauth/authentication';
import logoProjeto from 'root-images/logo_projeto.png';

export type AppMainLayoutPropType = {};

/**
 * Componente que define o layout principal da aplicação e que chama a validação de login
 *
 * @author davi takayama <davi.takayama@kepha.com.br>
 * @param {AppMainLayoutPropType} props
 */
function AppMainLayout(props: AppMainLayoutPropType): JSX.Element {
  const classes = useStyles(props);
  const isLoading = useSelector<MainStateType, boolean>(
    (state) => state.appLayoutReducer.mainLoading
  );
  const [loading, setLoading] = useState(true);
  const history = useNavigate();

  useComponentDidMount(() => {
    const oauth = new Authentication();

    oauth
      .refreshToken()
      .then(() => {

        setLoading(false);
      })
      .catch(tratarError);
  });

  function tratarError() {
    history('/login');
    setLoading(false);
  }


  useEffect(() => localStorage.removeItem('formStack'), [history])

  return (
    <>
      <div className={classes.main}>
        {loading && (
          <div className={classes.cardIconLoading}>
            <img alt='logo' className={classes.iconLoading} src={logoProjeto} />
            Carregando...
          </div>
        )}
        {!loading && (
          <>
            <MenuLateral />
            <div className={classes.containerToolbarAndRoutes}>
              <Loading
                show={isLoading}
                style={{ zIndex: 100, position: 'absolute' }}
              />

              <MainToolbar />

              <div className={classes.contentContainer}>
                <Routes>
                  {viewsRoutes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={<route.component />}
                    />
                  ))}
                </Routes>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AppMainLayout;
