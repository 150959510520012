import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import AppMainLayout from 'root-views';
import Login from 'root-views/login/login';
import RecuperarSenha from 'root-views/recuperarsenha/recuperar-senha';
import ConfirmarCadastro from 'root-views/confirmarcadastro/confirmar-cadastro';
import ConfirmarConta from 'root-views/confirmarconta/confirmar-conta';

function Redirect({ to }: any) {
  let navigate = useNavigate();
  const navigateAux = useCallback(navigate, [navigate]);
  useEffect(() => {
    navigateAux(to);
  }, [navigateAux, to]);
  return null;
}
/**
 * Cria e declara as rotas principais do App
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 */
function createRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ <Redirect to='/app' />} />
        <Route path='/login' element={ <Login />} />
        <Route path='/resetar-senha' element={ <RecuperarSenha />} />
        <Route path='/confirmar-cadastro' element={ <ConfirmarCadastro />} />
        <Route path='/confirmar-conta/:id' element={ <ConfirmarConta />} />
        <Route path='/app/*' element={ <AppMainLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default createRoutes;
