import ButtonFAB from '@kepha/sumora-react-components/lib/buttonfab';
import TipoFiltroEnum from '@kepha/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import FilterData from '@kepha/sumora-react-components/lib/cardfilters/filter-data';
import FilterModel from '@kepha/sumora-react-components/lib/cardfilters/models/filter';
import DataTable from '@kepha/sumora-react-components/lib/datatable';
import GroupButtonGrid from '@kepha/sumora-react-components/lib/groupbuttongrid';
import { useComponentDidMount } from '@kepha/sumora-react-components/lib/utils/hooks';
import useMessages from '@kepha/sumora-react-components/lib/utils/language';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import CardFilters from 'root-components/cardfilters/card-filters';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import Swal from 'root-components/swal/swal';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import Usuario from 'root-models/usuario';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import ptBR from './i18n/ptBR';
import { useStyles } from './usuarios.styles';

/** MÉTODOS AUXILIARES PARA SIMULAR UMA API */

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const nomes = [
  'Roberto',
  'Maria',
  'José',
  'Mauricio',
  'Larissa',
  'Gabriel',
  'Bruno',
  'Gabriela',
  'João',
  'Murilo'
];

function getFakeData() {
  const dataAux: Usuario[] = [];

  const qtdDados = getRandomInt(50, 100);
  for (let i = 0; i < qtdDados; i++) {
    const nome = nomes[getRandomInt(0, nomes.length - 1)];
    dataAux.push({
      idUsuario: i,
      nmUsuario: nome,
      dsEmail: `${nome}@email.com`,
      stUsuario: getRandomInt(0, 1)
    });
  }

  return dataAux;
}

async function apiRequestSimulation(): Promise<{ data: Usuario[]; status: number }> {
  return new Promise((resolve, reject) => {
    if (getRandomInt(0, 100) === 22) {
      reject('Ocorreu um erro');
    } else {
      setTimeout(() => {
        resolve({ data: getFakeData(), status: 200 });
      }, 3000);
    }
  });
}
/** MÉTODOS AUXILIARES PARA SIMULAR UMA API */

export type UsuariosPropType = {};

/**
 * Tela de listagem de Usuários
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {UsuariosPropType} props
 */
function Usuarios(props: UsuariosPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const classes = useStyles(props);
  const theme = useTheme();
  const history = useNavigate();
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'es-ES': esES, 'en-US': enUS });

  const [data, setData] = useState<Usuario[]>([]);
  const [dataFiltered, setDataFiltered] = useState<Usuario[]>([]);
  const [selected, setSelected] = useState<Usuario | undefined>();

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar(formatMessage('usuario.usuarios'));
    loadData();
  });

  return (
    <div className={classes.rootListagem}>
      <CardFilters
        screenKey={ScreensKeysEnum.USUARIOS}
        data={data}
        onLoadData={loadData}
        onRefreshFilters={setDataFiltered}
        filtersDefinitions={[
          {
            field: 'nmUsuario',
            label: 'Nome',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              suggestions: nomes,
              getLabel: '',
              getValue: ''
            }
          },
          {
            field: 'dsEmail',
            label: 'E-mail',
            type: TipoFiltroEnum.STRING
          },
          {
            field: 'stUsuario',
            label: 'Situação',
            selectableForSorting: false,
            type: TipoFiltroEnum.ENUM,
            enumOptions: [
              {
                label: formatMessage('common.ativo'),
                value: SituacaoEnum.ATIVO
              },
              {
                label: formatMessage('common.inativo'),
                value: SituacaoEnum.INATIVO
              }
            ]
          }
        ]}
      />

      <GroupButtonGrid
        showAdd={false}
        showEdit
        showDelete
        disableEdit={!selected}
        disableDelete={!selected}
        onClickEdit={() => {
          if (!selected) return;
          history(`/app/usuario/editar/${selected.idUsuario}`);
        }}
        onClickDelete={handleDelete}
      />

      <div className={`${classes.containerDataTable} tabela`}>
        <DataTable<Usuario>
          columns={[
            {
              field: 'nmUsuario',
              headerName: 'Nome',
              col: 4
            },
            {
              field: 'dsEmail',
              headerName: 'E-mail',
              col: 4
            },
            {
              valueGetter: node =>
                formatMessage(
                  node.data.stUsuario === SituacaoEnum.ATIVO ? 'common.ativo' : 'common.inativo'
                ),
              headerName: 'Situação do usuário',
              col: 4,
              colId: 'stUsuario'
            }
          ]}
          data={dataFiltered}
          onSelectRow={setSelected}
        />
      </div>

      <ButtonFAB onClick={() => history('/app/usuario/novo')}>
        <AddIcon />
      </ButtonFAB>
    </div>
  );

  /**
   * Carrega os dados
   *
   * @param {FilterModel[]} [filters=[]] - Filtros para os dados
   */
  function loadData(filters: FilterModel[] = []) {
    appLayoutActions.setLoading(true);

    apiRequestSimulation()
      .then(res => {
        setDataFiltered(FilterData.applyFilters(res.data, filters));
        setData(res.data);
      })
      .catch(err => {
        setDataFiltered([]);
        setData([]);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('common.ocorreuUmErro'),
          text: formatMessage('common.falhaAoCarregarDados'),
          icon: 'error'
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }

  /**
   * Deleta um usuário
   */
  function handleDelete() {
    /* "selected" contém as informações do usuário para deletar */

    Swal({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: theme.palette.error.main,
      cancelButtonColor: theme.palette.primary.main,
      cancelButtonText: formatMessage('common.cancelar'),
      confirmButtonText: formatMessage('common.confirmar'),
      title: formatMessage('common.aviso'),
      text: formatMessage('usuario.desejaDeletarEsteUsuario'),
      icon: 'warning'
    }).then(res => {
      if (!res.value) return;
      appLayoutActions.setLoading(true);

      apiRequestSimulation()
        .then(res => {
          Swal({
            showConfirmButton: true,
            title: formatMessage('common.sucesso'),
            text: formatMessage('usuario.usuarioDeletadoComSucesso'),
            icon: 'success'
          });
        })
        .catch(err => {
          Swal({
            showConfirmButton: true,
            title: formatMessage('common.ocorreuUmErro'),
            text: formatMessage('usuario.falhaAoDeletar'),
            icon: 'error'
          });
        })
        .finally(() => appLayoutActions.setLoading(false));
    });
  }
}

export default Usuarios;
