import { makeStyles } from '@mui/styles';

export const toolbarHeight = 80;

const useStyles = makeStyles((theme: any) => ({
  container: {
    minHeight: toolbarHeight,
    maxHeight: toolbarHeight,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingLeft: 14,
    paddingRight: 25,
    background: '#FFF'
  },
  containerLeftItem: {
    display: 'flex',
    alignItems: 'center'
  },
  containerRightItem: {
    fontSize: 25
  },
  buttonMenu: {
    width: '24px !important',
    height: 24,
    minWidth: '35px !important',
    backgroundColor: '#fff !important',
    color: `${theme.palette.primary.main} !important`,
  },
  titleToolbar: {
    marginLeft: 15,
    fontWeight: 'bold',
    fontFamily: 'Akrobat',
    fontSize: '28px',
    color: theme.palette.text.secondary
  }
}));

export { useStyles };
