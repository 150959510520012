import { makeStyles } from '@mui/styles';

export const menuLateralMaxWidth = 270;
export const menuLateralMinWidth = 50;

const useStyles = makeStyles((theme: any) => ({
  menuDrawer: {
    backgroundColor: `${theme.palette.background.default} !important`,
    width: menuLateralMaxWidth,
    height: '100vh',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    borderRightWidth: 0
  },
  menuDrawerOpen: {
    width: menuLateralMaxWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuDrawerClose: {
    overflowX: 'hidden',
    width: menuLateralMinWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  containerLogo: {
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default
  },
  showImage: {},
  hiddenImage: {},
  logoProjeto: {
    height: 55,
    width: 155
  },
  iconProjeto: {
    height: 30,
    width: 30
  },
  containerItens: {
    paddingTop: 0,
    paddingBottom: 0,
    color: '#5B6478 !important',
    overflowX: 'hidden'
  },
  containerItem: {
    paddingLeft: '10px !important'
  },
  itemIcon: {
    color: '#5B6478 !important',
    fontSize: 30,
    minWidth: 'auto',
    width: 40
  },
  scrollMenu: {
    overflowY: 'auto'
  },
  scrollMenuClosed: {
    overflow: 'hidden'
  }
}));

export { useStyles };
