import Button from '@kepha/sumora-react-components/lib/button';
import useMessages from '@kepha/sumora-react-components/lib/utils/language';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Notifications from '@mui/icons-material/Notifications';
import PerfilIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import Authentication from 'root-resources/oauth/authentication';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import ptBR from './i18n/ptBR';
import { useStyles } from './toolbar.styles';

export type MainToolbarPropType = {};

/**
 * Representa o header/toolbar do layout principal
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {MainToolbarPropType} props
 */
function MainToolbar(props: MainToolbarPropType): JSX.Element {
  const classes = useStyles(props);
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'es-ES': esES, 'en-US': enUS });
  const history = useNavigate();
  const titleToolbar = useSelector<MainStateType, string>(state => state.appLayoutReducer.titleToolbar);
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

  return (
    <div className={classes.container}>
      <div className={classes.containerLeftItem}>
        <Tooltip title={formatMessage('mainToolbar.abrirFecharMenuLateral')}>
          <div>
            <Button className={classes.buttonMenu} onClick={() => appLayoutActions.setMenuIsOpen()} disableElevation>
              <MenuIcon color='inherit' />
            </Button>
          </div>
        </Tooltip>

        <span className={classes.titleToolbar}>{titleToolbar}</span>
      </div>

      <div className={classes.containerRightItem}>
        <Tooltip title={formatMessage('mainToolbar.notificacoes')}>
          <IconButton style={{ fontSize: 'inherit', color: '#C3D2E3' }}>
            <Notifications fontSize='inherit' />
          </IconButton>
        </Tooltip>
        <Tooltip title={formatMessage('mainToolbar.perfil')}>
          <IconButton onClick={() => history('/app/perfil')} style={{ fontSize: 'inherit', color: '#C3D2E3' }}>
            <PerfilIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>

        <Tooltip title={formatMessage('mainToolbar.sair')}>
          <IconButton
            onClick={() => {
              Authentication.logout();
              history('/login');
            }}
            style={{ fontSize: 'inherit', color: '#C3D2E3' }}
          >
            <Logout fontSize='inherit' />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

export default MainToolbar;
