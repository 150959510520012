/**
 * Enum de keys das telas do sistema para diferenciar o componente CardFilters de cada uma
 *
 * @author Bruno Eduardo
 */
enum ScreensKeysEnum {
  USUARIOS = 'USUARIOS',
}

export default ScreensKeysEnum;
