import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { MenuLateralItemModel } from './menu-itens-definitions';
import { useStyles } from './menu.styles';

export type MenuLateralItemPropType = {
  /**
   * Definições do item que vai ser renderizado nesse componente
   */
  item: MenuLateralItemModel;
  /**
   * Nível do item no menu lateral
   * valor inicial=0
   */
  level: number;
};

/**
 * Representa um item do menu lateral, em qualquer nível
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {MenuLateralItemPropType} props
 */
function MenuLateralItem(props: MenuLateralItemPropType): JSX.Element {
  const classes = useStyles(props);
  const { item, level } = props;
  const [collapseIsOpen, setCollapseIsOpen] = useState<boolean | undefined>(undefined);

  const history = useNavigate();

  const menuIsOpen = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.menuOpen);
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

  useEffect(() => {
    !menuIsOpen && setCollapseIsOpen(false);
  }, [menuIsOpen]);

  return (
    <>
      <ListItem
        button
        className={!menuIsOpen ? classes.containerItem : undefined}
        onClick={() => {
          appLayoutActions.setMenuIsOpen(true);

          if (item.childrens && item.childrens.length > 0) {
            setCollapseIsOpen(!collapseIsOpen);
            return;
          }

          if (item.route) {
            menuIsOpen && appLayoutActions.setMenuIsOpen(false);
            history(item.route, { replace: true });
          }
        }}
        style={level ? { boxSizing: 'border-box', paddingLeft: 40 + 20 * level, color: '#FFF' } : undefined}
      >
        {item.Icon && (
          <ListItemIcon className={classes.itemIcon}>{item.Icon({ fontSize: 'inherit' })}</ListItemIcon>
        )}

        <ListItemText primary={item.label} />

        {item.childrens && item.childrens.length > 0 && (
          <IconButton edge='end' color='inherit'>
            <ArrowIcon
              color='inherit'
              style={
                collapseIsOpen !== undefined
                  ? {
                      transform: collapseIsOpen ? 'rotate(90deg)' : undefined,
                      transition: 'transform 0.3s linear'
                    }
                  : undefined
              }
            />
          </IconButton>
        )}
      </ListItem>

      {item.childrens && item.childrens.length > 0 && (
        <Collapse
          in={collapseIsOpen && menuIsOpen}
          timeout='auto'
          unmountOnExit
          style={
            item.childrensBackgroundColor
              ? { backgroundColor: item.childrensBackgroundColor }
              : undefined
          }
        >
          <List component='div' className={classes.containerItens}>
            {item.childrens.map((subItem, i) => (
              <MenuLateralItem item={subItem} level={level + 1} key={i} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

export default MenuLateralItem;
