import GenericApi from 'root-resources/generic-api';
import Skuld from 'root-models/skuld';
import { AxiosResponse } from 'axios';

/**
 * API do Skuld
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @extends {RootApi}
 */
class SkuldAPI extends GenericApi<Skuld> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Skuld
   */
  public constructor() {
    super({ baseEndpoint: '/skuld' });
  }

  public async save(): Promise<AxiosResponse<Skuld>> {
    return this.api.post<Skuld>('/');
  }
}

export default SkuldAPI;
