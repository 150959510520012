import {
    ButtonFABMenu, Card, DatePicker, Select,
    Switch, TextField, TimePicker
} from '@kepha/sumora-react-components/lib';
import { useComponentDidMount } from '@kepha/sumora-react-components/lib/utils/hooks';
import useMessages from '@kepha/sumora-react-components/lib/utils/language';
import { Grid } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Params } from 'react-router-dom';
import { Dispatch } from 'redux';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import Swal from 'root-components/swal/swal';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import TipoUsuarioEnum from 'root-enumerations/tipo-usuario-enum';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import * as Yup from 'yup';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import ptBR from './i18n/ptBR';

/**
 * Tipo dos valores do formik
 */
export type EditarUsuarioFormikValuesType = {
  nmUsuario: string;
  dsEmail: string;
  stUsuario: SituacaoEnum | '';
  tpPerfil: TipoUsuarioEnum | '';
  dtInicio: Date;
  dhInicio: Date;
};

export type EditarUsuarioPropType = {};

/**
 * View de edição de usuário
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @param {EditarUsuarioPropType} props
 */
function EditarUsuario(props: EditarUsuarioPropType): JSX.Element {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const isLoading = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.mainLoading);
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'es-ES': esES, 'en-US': enUS });
  const history = useNavigate();
  const { id }: Readonly<Params<'id'>> = useParams();

  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState<EditarUsuarioFormikValuesType>({
    nmUsuario: '',
    dsEmail: '',
    stUsuario: SituacaoEnum.ATIVO,
    tpPerfil: '',
    dtInicio: new Date(),
    dhInicio: new Date()
  });

  const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } =
    useFormik<EditarUsuarioFormikValuesType>({
      validateOnBlur: true,
      validateOnChange: false,
      enableReinitialize,
      initialValues,
      validationSchema: Yup.object().shape({
        nmUsuario: Yup.string().required(formatMessage('common.campoObrigatorio')),
        dsEmail: Yup.string()
          .email(formatMessage('common.emailInvalido'))
          .required(formatMessage('common.campoObrigatorio')),
        tpPerfil: Yup.number().required(formatMessage('common.campoObrigatorio')),
        stUsuario: Yup.number().required(formatMessage('common.campoObrigatorio'))
      }),
      onSubmit: handleSubmitFormik
    });

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar(
      formatMessage(id ? 'usuario.edicaoDeUsuario' : 'usuario.cadastroDeUsuario')
    );

    if (id) {
      setEnableReinitialize(true);
    }
  });

  useEffect(() => {
    if (!enableReinitialize) return;
    appLayoutActions.setLoading(true);

    setTimeout(() => {
      setInitialValues({
        nmUsuario: 'Bruno Eduardo',
        dsEmail: 'bruno.soares@kepha.com.br',
        stUsuario: SituacaoEnum.ATIVO,
        tpPerfil: TipoUsuarioEnum.GERENTE,
        dtInicio: new Date(),
        dhInicio: new Date()
      });
      setEnableReinitialize(false);
      appLayoutActions.setLoading(false);
    }, 3000);

    // eslint-disable-next-line
  }, [enableReinitialize]);

  return (
    <Card title={formatMessage('common.identificacao')}>
      <Grid container style={{ height: 70 }}>
        <Grid item xs={4} style={{ paddingRight: 15 }}>
          <TextField
            variant='standard'
            label={formatMessage('common.nome')}
            name='nmUsuario'
            value={values.nmUsuario}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.nmUsuario && errors.nmUsuario !== undefined}
            helperText={touched.nmUsuario && errors.nmUsuario !== undefined ? `${errors.nmUsuario}` : ''}
          />
        </Grid>

        <Grid item xs={4} style={{ paddingRight: 15 }}>
          <TextField
            variant='standard'
            label='E-mail'
            name='dsEmail'
            type='email'
            value={values.dsEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.dsEmail && errors.dsEmail !== undefined}
            helperText={touched.dsEmail && errors.dsEmail !== undefined ? `${errors.dsEmail}` : ''}
          />
        </Grid>

        <Grid item xs={2} style={{ paddingRight: 15 }}>
          <Select<string, number>
            label={formatMessage('usuario.tipoDeUsuario')}
            value={typeof values.tpPerfil !== 'string' ? values.tpPerfil : null}
            onChangeValue={(value, e) => setFieldValue('tpPerfil', e.target.value)}
            onBlur={handleBlur}
            placeholder='Selecione...'
            options={[
              formatMessage('usuario.representante'),
              formatMessage('usuario.gerente'),
              formatMessage('usuario.tecnico'),
              formatMessage('usuario.produtor')
            ]}
            getOptionLabel={opt => opt}
            getOptionValue={(opt, i) => i}
            error={touched.tpPerfil && errors.tpPerfil !== undefined}
            helperText={touched.tpPerfil && errors.tpPerfil !== undefined ? `${errors.tpPerfil}` : ''}
          />
        </Grid>

        <Grid item xs={2}>
          <Switch
              label={'Ativo'}
              checked={false}
              defaultChecked
              onBlur={handleBlur}
              onChange={() => {}}
            />
        </Grid>
      </Grid>

      <Grid container style={{ height: 70 }}>
        <Grid item xs={2} style={{ paddingRight: 15 }}>
          <DatePicker
            label='Dia de inicio'
            value={values.dtInicio}
            minDate={new Date('2020-01-03')}
            maxDate={new Date('2022-12-04')}
            onChange={(event: any) => {
              setFieldValue('dtInicio', event);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TimePicker
            label='Hora de inicio'
            value={values.dhInicio}
            onChange={(event: any) => {
              setFieldValue('dhInicio', event);
            }}
          />
        </Grid>
      </Grid>

      <ButtonFABMenu
        disabled={isLoading}
        primaryAction={{ onClick: (e: any) => handleSubmit(e),  iconProps: {color: 'inherit'} }}
        secondaryAction={{ onClick: () => history('/app/usuarios'),  iconProps: {color: 'inherit'} }}
      />
    </Card>
  );

  /**
   * Manipula o evento de submit do Formik
   *
   * @param {EditarUsuarioFormikValuesType} values - Valores do submit
   * @param {FormikHelpers<EditarUsuarioFormikValuesType>} formikHelpers - Auxiliares
   */
  function handleSubmitFormik(
    values: EditarUsuarioFormikValuesType,
    formikHelpers: FormikHelpers<EditarUsuarioFormikValuesType>
  ) {
    LoadingSwal({ text: formatMessage('common.carregando') });

    setTimeout(() => {
      Swal({
        showConfirmButton: true,
        title: formatMessage('common.sucesso'),
        text: formatMessage(id ? 'common.editadoComSucesso' : 'common.cadastradoComSucesso'),
        icon: 'success'
      });

      history('/app/usuarios');
    }, 3000);
  }
}

export default EditarUsuario;
