import Dashboard from 'root-views/dashboard/dashboard';
import Perfil from 'root-views/perfil/perfil';
import Usuarios from 'root-views/usuario/usuarios';
import EditarUsuario from 'root-views/usuario/editar-usuario';
import Gantt from 'root-views/gantt/gantt';

export type ScreenRoutesType = {
  path: string;
  exactPath?: boolean;
  component: any;
};

/**
 * Constante com as definições das rotas das Screens/Views/Telas da aplicação
 */
const viewsRoutes: ScreenRoutesType[] = [
  {
    path: '/',
    exactPath: true,
    component: Dashboard,
  },
  {
    path: '/perfil',
    exactPath: true,
    component: Perfil,
  },
  {
    path: '/usuarios',
    exactPath: true,
    component: Usuarios,
  },
  {
    path: '/usuario/novo',
    component: EditarUsuario,
  },
  {
    path: '/usuario/editar/:id',
    component: EditarUsuario,
  },
  {
    path: '/gantt',
    component: Gantt,
  },
];

export default viewsRoutes;
