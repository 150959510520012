import React, { useState, useEffect } from 'react';
import useMessages from '@kepha/sumora-react-components/lib/utils/language';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import { useParams, Params } from 'react-router-dom';
import Loading from '@kepha/sumora-react-components/lib/loading';
import { useStyles } from './confirmar-conta.style';
// import Admin from 'root-resources/oauth/admin';
import Swal from 'root-components/swal/swal';
import './confirmar-conta.css';

const logo = require('root-images/logo_projeto.png');

export type ConfirmarCadastroPropType = {};

/**
 * View para confirmar a conta do cliente
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function ConfirmarCadastro(props: ConfirmarCadastroPropType): JSX.Element {
  const classes = useStyles(props);
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES });
  const { id }: Readonly<Params<'id'>> = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(false);
    setError(true);
    Swal({
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Ok',
      title: formatMessage('confirmarConta.falhaAoCarregarDados'),
      icon: 'error'
    });

    // const admin = new Admin();
    // admin
    //   .validarCadastro(id)
    //   .then(() => {
    //     setLoading(false);
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //     setError(true);
    //     Swal({
    //       showConfirmButton: false,
    //       showCancelButton: true,
    //       cancelButtonText: 'Ok',
    //       title: formatMessage('confirmarConta.falhaAoCarregarDados'),
    //       icon: 'error',
    //     });
    //   });
  }, [formatMessage, id]);

  return (
    <div>
      <Loading show={!error && loading} />

      <div className={`${classes.card} ${loading ? 'run-write-open' : 'run-write-close'}`}>
        <img src={logo} alt='Logomarca ' />

        <div className={classes.text}>{formatMessage('confirmarConta.aguarde')}</div>
      </div>

      <div className={`${classes.card} ${loading ? 'run-write-open-sucess' : 'run-write-close-sucess'}`}>
        <img className={classes.logo} src={logo} alt='Logomarca ' />

        <div className={`${classes.text} ${classes.confirmacao}`}>
          {!error && formatMessage('confirmarConta.confirmacao')}
        </div>
      </div>
    </div>
  );
}

export default ConfirmarCadastro;
