import { makeStyles } from '@mui/styles';
import backgroundImage from 'root-images/background_image.png';

/**
 * Estilos da view de login
 **/
const useStyles = makeStyles({
  background: {
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  card: {
    backgroundColor: '#FFFFFF',
    borderRadius: '10px !important',
    transition: ' height 220ms',
    width: 360,
    paddingTop: 100,
    marginTop: -100
  },
  grid: {
    marginTop: 30
  },
  esqueceuSenha: {
    paddingRight: 12,
    display: 'flex',
    width: '100%',
    height: 60,
    marginBottom: 10,
    fontSize: 14,
    color: '#0e95ef',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300
  },
  label: {
    textDecoration: 'none',
    color: '#0e95ef',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row-reverse'
  },
  button: {
    marginBottom: 15,
    justifyContent: 'center',
    display: 'flex',
    width: '100%'
  },
  labelButton: {
    width: 126,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeight: 300
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  textField: {
    height: 75,
  },
  textPassword: {
    overflow: 'hidden',
    transition: 'max-height 220ms',
    width: '100%',
    padding: '24px 0 0 24px'
  }
});

export { useStyles };
