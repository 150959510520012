import { useStyles } from './gantt.styles'
import {
  GanttComponent,
  Inject,
  Edit,
  Selection,
  Toolbar,
  ColumnsDirective,
  ColumnDirective,
  VirtualScroll,
} from '@syncfusion/ej2-react-gantt'
import SkuldAPI from 'root-resources/api/skuld'
import { useEffect, useState } from 'react'
import Test from 'root-models/skuld'
import { ButtonFABMenu } from '@kepha/sumora-react-components'
import refreshIcon from 'root-images/refresh.svg'
import reloadIcon from 'root-images/reload.svg'
import Swal from 'root-components/swal/swal'
import LoadingSwal from 'root-components/loadingswal/loading-swal'
import SwalClose from 'sweetalert2'

export type GanttPropType = {}

function Gantt(props: GanttPropType): JSX.Element {
  const skuldAPI = new SkuldAPI()
  let initial = true
  let gridObj: any

  const [ganttList, setGanttList] = useState<Test[]>([])

  useEffect(() => {
    atualizarAPI()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function recarregarAPI() {
    skuldAPI
      .save()
      .then(() => {
        Swal({
          showConfirmButton: true,
          title: 'Regarregando...',
          text: 'A API está regarregando, atualize em algus minutos',
          icon: 'warning',
        })
      })
      .catch(() => {
        Swal({
          showConfirmButton: true,
          title: 'Ocorreu em erro',
          text: 'Ocorreu um erro ao atualizar as informações, tente novamente',
          icon: 'error',
        })
      })
  }

  function atualizarAPI() {
    LoadingSwal({ text: 'Carregando' })
    skuldAPI
      .findAll()
      .then((result) => {
        SwalClose.close()
        setGanttList(result.data)
      })
      .catch((err) => {
        Swal({
          showConfirmButton: true,
          title: 'Ocorreu em erro',
          text: 'Ocorreu um erro ao buscar as informações, tente novamente',
          icon: 'error',
        })
      })
  }

  const classes = useStyles(props)
  const taskFields = {
    id: 'taskId',
    name: 'taskName',
    startDate: 'startDate',
    duration: 'duration',
    progress: 'progress',
    child: 'child',
    dependency: 'predecessor',
  }

  const editSettings: any = {
    allowEditing: false,
    mode: 'Auto',
  }

  const timelineSettings: any = {
    weekStartDay: 0,
    timelineViewMode: 'Week',
    workWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    topTier: {
      format: 'dd/MM/yyyy',
    },
  }

  const toolbarOptions: any = [
    'ExpandAll',
    'CollapseAll',
    'ZoomIn',
    'ZoomOut',
    'ZoomToFit',
  ]

  const dataBound = () => {
    if (gridObj && gridObj.ganttChartModule && initial === true) {
      initial = false
      gridObj.ganttChartModule.expandCollapseAll()
    }
  }

  return (
    <div className={classes.rootListagem}>
      <GanttComponent
        dataSource={ganttList}
        taskFields={taskFields}
        allowSelection={true}
        editSettings={editSettings}
        timelineSettings={timelineSettings}
        includeWeekend={false}
        toolbar={toolbarOptions}
        ref={(grid) => (gridObj = grid)}
        dataBound={dataBound}
        enableVirtualization={true}
        height={'100%'}
        workWeek={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']}>
        <ColumnsDirective>
          <ColumnDirective
            field='TaskID'
            headerText=' '
            visible={true}
            maxWidth={105}></ColumnDirective>
          <ColumnDirective
            field='TaskName'
            headerText='Nome'
            minWidth={550}></ColumnDirective>
          <ColumnDirective
            field='startDate'
            headerText='Duração'></ColumnDirective>
          <ColumnDirective
            field='progress'
            headerText='Progresso'></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Edit, Selection, Toolbar, VirtualScroll]} />
      </GanttComponent>

      <ButtonFABMenu
        primaryAction={{
          show: false,
        }}
        secondaryAction={{
          show: false,
        }}
        additionalActions={[
          {
            onClick: () => atualizarAPI(),
            icon: (
              <img
                src={refreshIcon}
                alt='ícone-relatorio'
                style={{ height: 21, width: 21, color: '#FFF' }}
              />
            ),
            tooltipLabel: 'Atualizar',
          },
          {
            onClick: () => recarregarAPI(),
            icon: (
              <img
                src={reloadIcon}
                alt='ícone-relatorio'
                style={{ height: 21, width: 21, color: '#FFF' }}
              />
            ),
            tooltipLabel: 'Recarregar',
          },
        ]}
      />
    </div>
  )
}

export default Gantt
